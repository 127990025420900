/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ lang, title, path, description }) {
    
    const { site } = useStaticQuery(
        graphql`
			query {
				site {
					siteMetadata {
						title
                        description
                        siteUrl
					}
				}
			}
		`
    )

    const metaDescription = description || site.siteMetadata.description

    const pageTitle = title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title



    return (
        <Helmet
            htmlAttributes={{lang}}
            title={pageTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: `https://www.akashi-kurihara.com/img/og.png`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                // {
                //     name: `twitter:site`,
                //     content: `@`,
                // },
                // {
                //     name: `twitter:creator`,
                //     content: `@`,
                // },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:image`,
                    content: `https://www.akashi-kurihara.com/img/og.png`,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]}

            link={[
                {
                    rel: `canonical`,
                    href: `${site.siteMetadata.siteUrl}${path}`
                }
            ]}
        />
    )
}

SEO.defaultProps = {
    lang: `ja`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
