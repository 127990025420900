import React from 'react'

import './reset.scss'


const Layout =  (props) => {
    return (
        <main className={props.className}>
            {props.children}
        </main>
    )
}

export default Layout
